class HeroWrapper {

  constructor(options){

    this.divAfter = document.querySelector(options.divAfter);
    this.arrowDown = document.querySelector(options.arrowDown);
    this.onArrowDownClick = this.onArrowDownClick.bind(this);

    if (this.arrowDown) {
      this.arrowDown.addEventListener('click', this.onArrowDownClick);
    }
  }

  onArrowDownClick(event) {
    event.preventDefault();
    const heroNextSiblingTop = this.divAfter.offsetTop;
    window.scrollTo({
      top: heroNextSiblingTop,
      left: 0,
      behavior: 'smooth'
    });
  }
};

document.addEventListener('DOMContentLoaded', function(){
  // const heroWrapper.init();
  const heroSwiper = new HeroWrapper({
    divAfter: '#hero-swiper--after',
    arrowDown: '.hero-swiper--plus a'
  });

  const heroWrapperManual = new HeroWrapper({
    divAfter: '.single-manuals',
    arrowDown: '.hero-wrapper--plus a'
  });
});