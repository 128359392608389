import Swiper from 'swiper';
import Misc from './misc';
import mediaOnLoad from './media-on-load';
import notifications from './notifications';
import ToolTips from './tooltips';
import './footer';
import './alm_content-tabs';
import './alm_bottom-popup';

const AlmTooltips = new ToolTips();
const madrigueraMediaOnLoad = new mediaOnLoad();

document.addEventListener('DOMContentLoaded', function() {
  
  // Swiper home
  const swiperHome = document.querySelectorAll('.swiper-home-container');
  if (swiperHome && swiperHome.length) {
    
    const mySwiper = new Swiper ('.swiper-container', {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      preloadImages: false,
      autoHeight: true,
      autoplay: {
        delay: 5000,
      },
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    });

    const swiperAboutUs = new Swiper ('.swiper-about-us', {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 4,
      spaceBetween: 30,
      preloadImages: false,
      // autoHeight: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.swiper-about-us .swiper-button-next',
        prevEl: '.swiper-about-us .swiper-button-prev',
      },
      pagination: {
        el: '.swiper-about-us .swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        720: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      }
    });

    window.addEventListener('resize', () => {
      if (Misc.getViewport().width <  730) {
        mySwiper.params.autoHeight = true;
      } else {
        mySwiper.params.autoHeight = false;
      }
      mySwiper.update();
      mySwiper.updateSize();
    })
  }

});