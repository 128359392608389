import misc from './misc';
const axios = require('axios');

export default class MediaOnLoad {
  constructor(){
    this.imageOnLoad = this.imageOnLoad.bind(this);
    this.vimeoThumbnailOnLoad = this.vimeoThumbnailOnLoad.bind(this);
    document.addEventListener('DOMContentLoaded', () => {
      this.imageOnLoad();
      this.backgroundImageOnLoad();
      this.vimeoThumbnailOnLoad();

    });
  }

  imageOnLoad(){
    const list = document.querySelectorAll('img[data-src]');

    window.addEventListener('scroll', () => {
      list.length && list.forEach( imageEl => {

        if (!misc.isScrolledIntoView(imageEl) || imageEl.classList.contains('loaded')) { return false; }
        var imageToLoad = new Image();
        if( misc.getViewport().width > 320 && imageEl.getAttribute('data-src-tablet') )
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-src-tablet') )
        else if( imageEl.getAttribute('data-src-mobile') )
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-src-mobile') )
        else
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-src') )
        imageToLoad.onload = function() { 
          imageEl.classList.remove('unloaded');
          imageEl.classList.add('loaded');
          imageEl.setAttribute('src', imageToLoad.src )
        }
      });
    });
  }
  backgroundImageOnLoad(){
    const list = document.querySelectorAll('[data-background-image]');
    const handleOnLoad = () => {
      list.length && list.forEach( imageEl => {
        if (!misc.isScrolledIntoView(imageEl, false) || imageEl.classList.contains('loaded')) { return false; }
        const imageToLoad = new Image();
        if( misc.getViewport().width > 320 && imageEl.getAttribute('data-background-image-tablet') )
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-background-image-tablet') )
        else if( imageEl.getAttribute('data-background-image-mobile') )
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-background-image-mobile') )
        else
          imageToLoad.setAttribute('src', imageEl.getAttribute('data-background-image') )
        imageToLoad.onload = function() { 
          imageEl.classList.remove('unloaded');
          imageEl.classList.add('loaded');
          imageEl.style.backgroundImage = `url(${imageToLoad.src})`;
        }
      });
    }
    handleOnLoad();
    window.addEventListener('scroll', () => {
      handleOnLoad();
    });

  }
  vimeoThumbnailOnLoad(){
    const list = document.querySelectorAll('.svg-lazy');
    window.addEventListener('scroll', () => {
      list.length && list.forEach( item => {

        const imageEl = item.querySelector('image[data-vimeo-id]');
        const imageIcon = item.querySelector('.fa');

        if (!misc.isScrolledIntoView(imageEl) || item.classList.contains('loading') || item.classList.contains('loaded')) { 
          return false; 
        }

        item.classList.add('loading');
        imageIcon.classList.remove('fa-play');
        imageIcon.classList.add('fa-circle-o-notch');

        axios.get(`${site.rest}alm/v1/manual-media`, {
          headers: {
            'X-WP-Nonce': site.nonce,
            'vimeo-id': imageEl.getAttribute('data-vimeo-id')
          }
        }).then(function (response) {
          item.classList.remove('unloaded');
          item.classList.add('loaded');
          item.classList.remove('loading');
          imageIcon.classList.remove('fa-circle-o-notch');
          imageIcon.classList.add('fa-play');
          imageEl.setAttribute('xlink:href', response.data )
        });

      });
    });

  }
}