import Misc from './misc';

class ALM_BottomPopup {
  constructor() {
    
    if (!document.querySelector('.bottom-popup')) {
      return;
    }

    this.bottomPopup = document.querySelector('.bottom-popup');
    this.bottomPopup.style.display = '';
    this.closePopup = this.closePopup.bind(this);

    this.bottomPopup.querySelector('.button').addEventListener('click', this.closePopup)

  } 

  closePopup(event) {
    event.preventDefault();
    Misc.setCookie(this.bottomPopup.getAttribute('data-id'), '0');
    this.bottomPopup.style.display = 'none';
  }

}

new ALM_BottomPopup();