import Misc from './misc';

(function($) {

class AlmHeader {
  
  constructor(){
    this.handleClick = this.handleClick.bind(this);
    this.enableMobileMenu = this.enableMobileMenu.bind(this);
    this.disableMobileMenu = this.disableMobileMenu.bind(this);
    this.onResize = this.onResize.bind(this);

    this.menuWrapper = document.querySelector('.header-responsivemenu .header-nav');

    this.trigger = document.querySelector('#main-menu-trigger');

    document.querySelector('#main-menu-trigger').addEventListener('click', this.handleClick )
    window.addEventListener('resize', this.onResize );

    if ( Misc.getViewport().width < 960) {
      this.enableMobileMenu();
    }

  }
  
  handleClick(event){
    event.preventDefault();

    const isUserLoggedIn = site.hasOwnProperty('isUserLoggedIn') && document.querySelector('#wpadminbar') ? site.isUserLoggedIn : false;
    const headerWrapper = document.querySelector('.header-wrapper');
    
    if (!this.menuWrapper.classList.contains('active')){
      this.menuWrapper.classList.add('active');
      this.trigger.classList.add('active');
      $(this.menuWrapper).slideDown(300);
    } else {

      $(this.menuWrapper).slideUp(300, () => {
        this.trigger.classList.remove('active');
        this.menuWrapper.classList.remove('active');
      });


    }

  }

  enableMobileMenu() {

    this.menuWrapper.querySelectorAll('.header-nav--ul > li.menu-item-has-children > a').forEach( item => {
      item.addEventListener('click', event => {

        event.preventDefault();

        if ( !event.currentTarget.classList.contains('active') ) {
          event.currentTarget.classList.add('active');
          Misc.slideDown($(event.currentTarget).parent().find('ul.sub-menu')[0], 300);
          $(event.currentTarget).parent().find('ul.sub-menu').slideDown(300);
        } else {
          event.currentTarget.classList.remove('active');
          Misc.slideUp($(event.currentTarget).parent().find('ul.sub-menu')[0], 300);
        }

      });
    });
  }

  disableMobileMenu() {
    this.menuWrapper.querySelectorAll('.header-nav--ul > li.menu-item-has-children > a').forEach( item => {
      item.removeEventListener('click', this.handleMobileMenu );
    });
  }

  onResize(){
    const headerWrapper = document.querySelector('.header-wrapper');

    if( Misc.getViewport().width > 720 && this.menuWrapper.classList.contains('active')){
      this.disableMobileMenu();
      this.menuWrapper.classList.remove('active');
      this.menuWrapper.style.display = '';
      headerWrapper.style.top = '';
    } else {
      this.enableMobileMenu();
    }
  }

}

// $(document).ready(function(){
document.addEventListener('DOMContentLoaded', function(){

  new AlmHeader();
});

})( jQuery );