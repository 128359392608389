import Misc from './misc';

(function($) {

  $('.footer-menu h4').on('click', function(event) {
    // if ( Misc.getViewport().width > Misc.getMediaQueries()['mobile-layout']) {
    if ( Misc.getViewport().width > 730) {
      return false;
    }
    event.preventDefault();
    $(this).parent().find('ul').slideToggle(300);
    $(this).toggleClass('active');
  });

  $(window).on('resize', function() {
    // if ( Misc.getViewport().width > Misc.getMediaQueries()['mobile-layout']) {
    if ( Misc.getViewport().width > 730) {
      $('.footer-menu .item-toggle ul').removeAttr('style');
      $('.footer-menu h4').removeClass('active');
    }
  })

  // console.log($);
})( jQuery );