document.addEventListener('DOMContentLoaded', function(){
  
  const registerForm = document.querySelector('form[name="register"]');
  if(!registerForm){ return false; 
  }

  const acceptTerms = registerForm.querySelector('#accept_terms')
  const submitButton = registerForm.querySelector('.tml-button[type="submit"]');
  if (!submitButton) { return false; }
  submitButton.disabled = 'disabled';
  acceptTerms.addEventListener('change', (event) => {
    if (event.currentTarget.checked) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.disabled = 'disabled';
    }
  })
});