import misc from './misc';

export default class ToolTips {
  
  constructor(options){
    this.options = options ? options : false;
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.checkTooltip = this.checkTooltip.bind(this)

    this.displayTooltip = this.displayTooltip.bind(this)
    this.removeTooltip = this.removeTooltip.bind(this)

    let targets = false;
    if( this.options && this.options.hasOwnProperty('rel') ){
      targets = document.querySelectorAll(`[rel="${this.options.rel}"]`);
    } else {
      targets = document.querySelectorAll('[rel="tooltip"]');
    }
    
    targets.forEach( item => {
      let isEventAdded = false;
      
      if (this.options.hasOwnProperty('openOnClick')) {
      
        return item.addEventListener('click', this.onMouseEnter);
      
      } else {

        // We define two different behaviors for touch and non touch devices
        ['mouseenter','touchstart'].forEach( eventItem => {
          
          if ('ontouchstart' in document.documentElement) {
            item.addEventListener(eventItem, this.onMouseEnter );
            isEventAdded = true;
          }

          if(!isEventAdded ){
            item.addEventListener(eventItem, this.onMouseEnter );
          }

        });

        // We don't want anything crazy to happen on click
        item.addEventListener('click', event => {
          event.preventDefault() 
        });

      }

    })
  
  }

  onMouseEnter(event = false){
    if (document.querySelector('#tooltip')) { return false; }
    const tooltipTrigger = event.target;
    this.loadTooltip(event.target);
  }

  loadTooltip(target) {
    const tooltipContent = target.getAttribute('title');
    const tooltip = document.createElement('div');
    
    if(!tooltipContent || tooltipContent == '') {
      console.error(`Attribute title is empty or not defined`);
      return false;
    }

    tooltip.id = 'tooltip';
    tooltip.innerHTML = `<div class="tooltip-inner">${tooltipContent}</div>`;
    document.body.appendChild(tooltip);

    // In case of touch event we add a class which will flag an special event
    this.displayTooltip(target);
  }

  displayTooltip( tooltipTrigger ){

    const tooltip = document.querySelector('#tooltip')

    if(window.innerWidth < tooltip.offsetWidth * 1.5){
      tooltip.style.maxWidth = window.innerWidth / 2;
    }
    else {
      tooltip.style.maxWidth = 340;
    }

    let pos_left = this.getOffset(tooltipTrigger).left + (tooltipTrigger.offsetWidth / 2) - (tooltip.offsetWidth / 2);
    let pos_top = this.getOffset(tooltipTrigger).top - tooltip.offsetHeight - 16;
    
    // console.log("top is", pos_top);
    if( pos_left < 0 ){
      pos_left = this.getOffset(tooltipTrigger).left + tooltipTrigger.offsetWidth / 2 - 20;
      tooltip.classList.add('left');
    } else {
      tooltip.classList.remove('left');
    }

    if( pos_left + tooltip.offsetWidth > window.innerWidth ) {
      pos_left = this.getOffset(tooltipTrigger).left - tooltip.offsetWidth + tooltipTrigger.offsetWidth / 2 + 20;
      tooltip.classList.add('right');
    } else {
      tooltip.classList.remove('right');
    }

    if( pos_top < 0 ) {
      pos_top = this.getOffset(tooltipTrigger).top + tooltipTrigger.offsetHeight + 15;
      tooltip.classList.add('top');
    }
    else {
      tooltip.classList.remove('top');
    }

    tooltip.style.left = `${pos_left}px`;
    tooltip.style.top = `${pos_top}px`;
    tooltip.classList.add('active');

    const afterAnimation = () => {
      tooltipTrigger.classList.add('active');
      tooltipTrigger.addEventListener( 'mouseleave', this.removeTooltip )
      
      document.addEventListener('touchstart', this.checkTooltip )
      tooltip.removeEventListener(misc.getAnimations(), afterAnimation )
    };    
    
    misc.getAnimations() && tooltip
      .addEventListener(misc.getAnimations(), afterAnimation );

  }

  removeTooltip() {
    const tooltipTrigger = document.querySelector('a[rel="tooltip"].active')

    document.querySelector("#tooltip") && document.body.removeChild(document.querySelector("#tooltip"));
    if( tooltipTrigger ){
      tooltipTrigger.classList.remove('active')
    }
  }

  checkTooltip(event){
    const tooltipTrigger = document.querySelector('a[rel="tooltip"].active')
    misc.hasClickedOutside(tooltipTrigger, event.target).then( () => {
      this.removeTooltip()
      document.removeEventListener('touchstart', this.checkTooltip )
    })
  }

  getOffset(elem) {

    let offsetLeft = 0;
    let offsetTop = 0;
    
    do {
      if ( !isNaN( elem.offsetLeft ) ){
        offsetLeft += elem.offsetLeft;
        offsetTop += elem.offsetTop;
      }
    } while( elem = elem.offsetParent );

    return {
      left: offsetLeft, 
      top: offsetTop
    }

  }

}