/**
 * 
 * DEPRECATED
 * Redeem notification is no longer used
 * 
 **/
import Misc from './misc';
import ToolTips from './tooltips';

class NotificationBoxItem {
  constructor(item, options) {
    this.options = options;
    this.item = item;
    this.onButtonClose = this.onButtonClose.bind(this);

    // Use this: <a class="op-close" href="#"><i class="fa fa-times"></i></a>
    const buttonClose = item.querySelector('.op-close');

    if (buttonClose) {
      // buttonClose.addEventListener(type: DOMString, callback: EventListener, capture?: boolean);
      buttonClose.addEventListener('click', this.onButtonClose);
    }

  }

  onButtonClose(event) {

    event.preventDefault();
    const thisButton = event.currentTarget;
    
    this.item.style.height = `${this.item.getBoundingClientRect().height}px`;
    this.item.classList.add('on-transition');
    this.item.classList.add('on-transition--closing');

    const afterTransition = () => {
      this.item.style.height = `${0}px`;
      this.item.style.margin = `${0}px`;
      this.item.style.padding = `${0}px`;
      Misc.getTransitions() && this.item.addEventListener(Misc.getTransitions(), afterTransitionEnd);
      Misc.getTransitions() && this.item.removeEventListener(Misc.getTransitions(), afterTransition);
    }

    const afterTransitionEnd = () => {
      this.item.classList.remove('on-transition--closing');
      this.item.classList.remove('on-transition');
      this.item.style.display = 'none';
      this.item.style.opacity = '0';
      if(thisButton.hasAttribute('data-close') && thisButton.getAttribute('data-close') === 'cookie') {
        Misc.setCookie('alm-dashboard-redeem', 'false', 365);
      }

      typeof this.options.onClose === 'function' && this.options.onClose();
      Misc.getTransitions() && this.item.removeEventListener(Misc.getTransitions(), afterTransitionEnd);
    }
    
    Misc.getTransitions() && this.item.addEventListener(Misc.getTransitions(), afterTransition);

  }
}

class NotificationBox {
  
  constructor(options) {
    this.list = document.querySelectorAll(options.elementClass);
    if(!this.list.length){ return false; }    
    
    this.list.forEach( item => { 
      return new NotificationBoxItem(item, options); 
    });
  }

}

const AlmNotifications = new NotificationBox({
  elementClass: '.notification-box--ui'
});

// const AlmNotificationsRedeem = new NotificationBox({
//   elementClass: '.notification-box--redeem',
//   onClose(){
//     const headerRedeem = document.querySelector('.header-dashboard--ul .redeem a');
//     headerRedeem.setAttribute('title', 'En el futuro, podrás activar tus tutoriales aquí también');
//     const tooltipRedeem = new ToolTips({
//       'rel': 'tooltip-redeem',
//       'openOnClick': true,
//     });
//     tooltipRedeem.loadTooltip(headerRedeem);
//     setTimeout(() => {
//       headerRedeem.removeAttribute('title');
//       tooltipRedeem.removeTooltip();
//     }, 6000);
//   }
// });

