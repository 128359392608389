// Product Tabs displaed on single-products.php
class AlmContentTabs {
  constructor(options) {

    // Wrapper
    this.wrapper = document.querySelector(options.wrapper);

    if (!this.wrapper) { return false; }

    // Elements to handle
    this.navList = this.wrapper.querySelectorAll('.content-tabs--nav a');
    this.contentList = this.wrapper.querySelectorAll('.content-tabs--item');

    // Methods
    this.handleTabNav = this.handleTabNav.bind(this);
    
    if ([].slice.call(this.contentList).length === 1) {
      [].slice.call(this.navList)[0].classList.add('active');
      [].slice.call(this.contentList)[0].classList.add('active');
    };

    [].slice.call(this.navList).forEach(item => {
      item.addEventListener('click', this.handleTabNav);
    });

  }

  handleTabNav(event){
    event.preventDefault();
    const targetIndex = Array.prototype.indexOf.call(event.currentTarget.parentElement.parentElement.children, event.currentTarget.parentElement);

    [].slice.call(this.contentList).forEach((item, index) => {
      if (index === targetIndex) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    });

    [].slice.call(this.navList).forEach((item, index) => {
      item.classList.remove('active');
    });

    event.currentTarget.classList.add('active');

  }
};

document.addEventListener('DOMContentLoaded', function() {

  // Tabs Single Products.php
  const AlmTabs = new AlmContentTabs({
    wrapper: '#content-tabs-index'
  });
});